.confirm-booking-starter {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.confirm-booking-border {
  width: 331px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #ffa5a5;
  display: inline-flex;
  flex-direction: column;
  gap: 28px;
  padding-left: 20px;
  padding-top: 20px;
}

.Confirm-booking-col {
  display: flex;
  align-items: flex-start;
  gap: 143px;
}

.confirm-booking-video {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.confirm-booking-video-u {
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.confirm-booking-video-l {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.confirm-booking-mode {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.confirm-booking-mode div {
  color: #ffa5a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.confirm-change {
  color: #566a95;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: end;
  text-align: flex-end;
  padding-right: 50px;
}

.confirm-booking-cont {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding-top: 28px;
}

.confirmation-page-conatiner {
  padding-left: 80px;
}

.coupon-code {
  display: inline-flex;
  align-items: flex-start;
  gap: 20px;
  padding-top: 56px;
}

.coupon-code input {
  width: 634.517px;
  height: 35.624px;
  border-radius: 5px;
  background: #f2f2f2;
  border: none;
}

.coupon-code input::placeholder {
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 344.004px;
  height: 17.12px;
  flex-shrink: 0;
  padding-left: 21px;
}

.coupon-line {
  width: 686px;
  height: 0.5px;
  background: #555;
  margin-top: 12px;
  margin-bottom: 8px;
}

.coupon-desc {
  color: #909090;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 56px;
}

.payment-det {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
}

.price-session {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.price-session-row {
  display: flex;
  align-items: flex-start;
  gap: 487px;
}

.price-session-row-1 {
  display: flex;
  align-items: flex-start;
  gap: 525px;
}

.payment-det-u {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.price-session-row-u {
  color: #444;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.price-session-row-l {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.price-session-row-1-u {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.price-session-row-1-l {
  color: #25ced1;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 51px;
}

.proceed-btn-chng {
  margin-left: 242px;
}
