.our-miss {
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* background-color: gray; */
  margin-top: 20px;
  margin-left: 108px;
  padding-top: 32px;
}

.landing-image {
  width: 600px;
  height: 400px;
}

.abt-divide {
  display: flex;
  align-items: flex-end;
  gap: 64px;
  padding-left: 108px;
  padding-top: 51px;
}
.our-mission {
  margin-top: 5px;
  color: #333;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.84px;
  text-transform: capitalize;
}

.our-mission::after {
  content: "";
  width: 8%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 210, 0, 1);
  display: block;
  height: 8px;
}

.mission-content {
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  margin-bottom: 10px;
}

.about-us-div {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 20px;
  /* background-color: gray; */
}

.abt-us {
  font-size: 42px;
  color: #333;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.84px;
  text-transform: capitalize;
  text-align: left;
}

.abt-us::after {
  content: "";
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 210, 0, 1);
  display: block;
  height: 8px;
  margin-top: 10px;
  position: relative;
  right: 160px;
}

.abt-content {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  text-align: left;
  width: 492px;
}

.our-team {
  display: flex;
  flex-direction: column;
  /* background-color: gray; */
  gap: 20px;
  margin-top: 50px;
  margin-left: 108px;
}

.header-team {
  color: #333;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.84px;
  text-transform: capitalize;
}

.team-members {
  display: flex;
  gap: 15px;
  justify-content: center;
  width: 100%;
}

.member {
  height: 400px;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
}

.photo-mem {
  width: 100%;
  height: 300px;
  /* background-color: lightcoral; */
  background-image: url("../Images/user.jpeg");

  background-size: cover;
  background-repeat: no-repeat;
}

.mem-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.name-mem {
  color: #555;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px; /* 155% */
  letter-spacing: 0.4px;
}

.desig-mem {
  color: #666;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px; /* 221.429% */
  letter-spacing: 0.28px;
}

@media only screen and (max-width: 600px) {
  .abt-divide {
    display: block;
    gap: 64px;
    padding-left: 108px;
    padding-top: 51px;
  }

  .landing-image {
    display: none;
  }

  .abt-us {
    font-size: 42px;
    color: #333;
    font-family: Roboto;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.84px;
    text-transform: capitalize;
    text-align: left;
    position: relative;
    right: 30px;
  }

  .abt-us::after {
    content: "";
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 210, 0, 1);
    display: block;
    height: 8px;
    margin-top: 10px;
    position: relative;
    right: 30px;
  }

  .abt-content {
    color: #333;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    text-align: inherit;
    width: 300px;
    position: relative;
    right: 75px;
  }

  .main-content {
    margin-left: 10px;
  }

  .our-mission {
    margin-top: 5px;
    color: #333;
    font-family: Roboto;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.84px;
    text-transform: capitalize;
    position: relative;
    right: 90px;
    width: 300px;
  }

  .our-mission::after {
    content: "";
    width: 30%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 210, 0, 1);
    display: block;
    height: 8px;
  }

  .mission-content {
    color: #333;
    text-align: inherit;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    margin-bottom: 10px;
    position: relative;
    right: 75px;
    width: 300px;
  }

  .header-team {
    color: #333;
    font-family: Roboto;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.84px;
    text-transform: capitalize;
    width: 300px;
    position: relative;
    right: 80px;
  }

  .team-members {
    display: block;
    gap: 15px;
    justify-content: center;
    width: 100%;
    position: relative;
    right: 45px;
  }

  .our-team {
    margin-bottom: -500px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1100px) {
  .mission-content {
    font-size: 18px;
    width: 1200px;
  }

  .our-mission {
    position: relative;
    left: 260px;
  }

  .team-members {
    width: 1250px;
  }

  .header-team {
    position: relative;
    left: 270px;
    padding-bottom: 20px;
  }
}
