.community3-starter {
  width: 471px;
  height: 62px;
  flex-shrink: 0;
  margin-bottom: 37px;
}

.community3-starter-h {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.community3-starter-p {
  color: #555;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Create-new-dis-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.dis-btn {
  display: flex;
  padding: 16px 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #25ced1;
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.new-dis-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.new-topic-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.new-topic-form div {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.new-topic-form input {
  width: 909px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #555;
}

.new-topic-form input::placeholder {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.new-topic-form input[type="text"] {
  padding: 21px; /* Adjust the padding value as needed */
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.new-topic-form-l {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.new-topic-form-l div {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.new-topic-form-l input {
  width: 909px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #555;
}
.comm-grp-cats-2-u {
  display: flex;
  gap: 25px;
}
.new-topic-form-l input::placeholder {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.new-topic-form-l input[type="text"] {
  padding: 21px; /* Adjust the padding value as needed */
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}

.new-dis-checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.new-dis-checkbox div {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.new-dis-checkbox input {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 720px) {
  .Create-new-dis-cont {
    position: relative;
    left: -60px;
    bottom: 70px;
    transform: scale(0.8);
  }

  .comm-grp-cats-2-u {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 20px;
  }

  .community3-starter1 {
    position: relative;
    left: 87px;
    margin-left: -100px;
  }
  .grp-cat {
    font-size: 18px;
  }
}
