.dashboard-main {
  display: flex;
}

.dashboard2-left {
  width: 316px;
  height: 1924px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Set the direction to column */
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.15);
}

.dashboard2-left-d {
  height: 2000px;
}

.dashboard-profile {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 60px;
  padding-bottom: 59px;
}

.admin-options {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.dashboard2-left-d .admin-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 20px 40px;
}

.admin-list-dashboard {
  display: flex;
  /* justify-content: center; */
  align-items: center;

  gap: 16px;
  /* padding: 20px 40px; */
  width: 317px;
  height: 91px;
  background: url(../images/dash-bg.png) no-repeat;
}

.admin-list-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-left: 40px;
}

.admin-list-cont div {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.admin-list {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dash-profile-name {
  color: #333;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-right-container {
  padding-left: 47px;
  padding-top: 60px;
}

.right-starter {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.right-starter-h {
  color: #000;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.right-starter-date {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-cards {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding-top: 43px;
  padding-bottom: 56px;
}

.dashboard-card1 {
  width: 326px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: linear-gradient(
    180deg,
    #f6c1c1 0%,
    rgba(246, 193, 193, 0.43) 100%
  );
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.dashboard-card2 {
  width: 326px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: linear-gradient(
    180deg,
    #47d2d4 0%,
    rgba(71, 210, 212, 0.27) 100%
  );
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.dashboard-card3 {
  width: 326px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: linear-gradient(
    180deg,
    #f7da54 0%,
    rgba(247, 218, 84, 0.44) 100%
  );
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.dashboard-cards-sec {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.card1-divide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assesment-score div {
  margin-bottom: 10px;
}

.assesment-total {
  color: #555;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 20px;
}

.total-scored {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.total-scored span {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.brain-icon {
  display: flex;
  width: 124px;
  height: 120px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  padding-top: 9px;
  padding-left: 9px;
}

.brain-icon img {
  width: 83.16px;
  height: 100px;
  flex-shrink: 0;
  opacity: 0.75;
  padding-left: 15px;
}

.upcoming-events {
  display: flex;
  gap: 735px;
}

.upcoming-event-p {
  color: #333;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.today-p {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-time {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  align-items: center;
  gap: 23px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.dash-counsellor-dash2 {
  display: flex;
  justify-content: center;
  display: flex;
  width: 470px;
  height: 160px;
  padding: 24px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 93px;
  border-radius: 10px;
  background: #f2f2f2;
}

.counsellor-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.counsellor-schedule {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.schedule-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.counsellor-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.schedule-cancel-dash2 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 8px;
}

.dash-counsellor-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.join-btn-dash2 {
  display: flex;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.join-reschedule-dash2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.reschedule-dash2 {
  color: #1fa9ab;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.counsellor-name-dash2 {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.counsellor-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.anx-dp-dash2 {
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.schedule-date-t-dash2 {
  color: #444;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.schedule-cancel-dash2 div {
  color: #566a95;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dash-counsellor-flex {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.upcoming-session-p {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-session-date {
  color: #333;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-session-1 {
  display: flex;
  align-items: flex-start;
  gap: 27px;
}

.upcoming-session-box {
  display: flex;
  align-items: center;
  gap: 111px;
}

.upcoming-session {
  display: flex;
  align-items: flex-start;
  gap: 27px;
  padding-top: 56px;
  padding-bottom: 28px;
}

.upcoming-session-box-flex {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
}

.upcoming-left-dash2 {
  width: 500px;
  height: 423.304px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.client-container {
  display: flex;
  align-items: flex-start;
  gap: 27px;
}

.upcoming-left-container {
  padding-top: 34px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.left-content-2 {
  display: inline-flex;
  align-items: center;
  width: 458px;

  gap: 102px;
  padding-bottom: 16.57px;
}

.upcoming-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.client-info {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
}

.client-name-h1 {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.client-desc {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.client-btn {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.left-content-1 {
  display: inline-flex;
  height: 86.135px;
  width: 458px;
  align-items: center;
  gap: 102px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f2f2f2;
}

.left-content {
  display: inline-flex;
  align-items: center;
  width: 458px;
  gap: 102px;
  padding-top: 16.57px;
  padding-bottom: 16.57px;
}

.client-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}

.client-reschedule {
  color: #566a95;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.client-reschedule-chat {
  color: #566a95;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.community-meeting {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 557px;
  padding-bottom: 32px;
  padding-top: 56px;
}

.community-meeting-p1 {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.community-date-p {
  color: #333;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.community-date img {
  width: 20px;
  height: 20px;
}

.community-date {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 23px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.last-container {
  width: 1024px;
  height: 293.696px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.last-box {
  text-align: center;
  padding-top: 10px;
}

.community-meeting-flex {
  display: inline-flex;
  align-items: center;
  gap: 56px;
  width: 984px;
  height: 88px;
  flex-shrink: 0;
}

.community-meeting-info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.info-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.community-meeting-h {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.community-meeting-p {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.community-meeting-time-p1 {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.already-join {
  color: #566a95;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.btn-gap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.last-cont-btn {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.gray {
  border-radius: 10px;
  background: #f2f2f2;
}

.last-cont-btn-r {
  color: #566a95;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.assessment-container {
  display: flex;
  width: 945px;
  height: 255px;
  padding: 0px 40px 24px 40px;
  align-items: flex-start;
  gap: 128px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.take-ass {
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
}

.take-ass-btn {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.take-ass-h {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 1;
  padding-top: 90px;
}

.corner-img-set {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: -39px;
}

.corner-img {
  position: absolute;
  z-index: 0;
  left: 1128px;
}

.take-ass-h {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assessment-banner {
  padding-top: 56px;
}

.cancel {
  display: none;
}

.res-time-schedule {
  display: none;
}

@media only screen and (max-width: 720px) {
  .dashboard2-left {
    display: none;
  }

  .dashboard2-left-slide {
    display: flex;
    position: absolute;
    z-index: 100;
  }

  .right-starter {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .dashboard-card1 {
    width: 312px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #eee;
    background: linear-gradient(
      180deg,
      #ffa5a5 0%,
      rgba(255, 165, 165, 0.43) 100%
    );
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
  }

  .assesment-score {
    width: 148.174px;
    height: 44.429px;
    flex-shrink: 0;
  }

  .dash-asses {
    position: relative;
    top: -15px;
  }

  .assesment-total {
    width: 148.174px;
    height: 13.571px;
    flex-shrink: 0;
    color: #555;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .total-scored {
    color: #333;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .brain-icon img {
    width: 66.528px;
    height: 80px;
  }

  .dash-card1 {
    position: relative;
    top: -20px;
  }

  .dashboard-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .brain-con-2 {
    display: none;
  }

  .dashboard-right-mb {
    position: relative;
    right: 5px;
  }

  .community-meeting-flex {
    width: 380px;
  }

  .upcoming-session-mb-2-chat {
    padding-top: 30px;
  }

  .upcoming-session-mb-comm2 {
    position: relative;
    left: 20px;
  }

  .dash-counsellor-banner-mb-d {
    margin-bottom: 40px;
  }

  .upcoming-session-box-mb-flex-bot {
    position: relative;
    left: 110px;
  }

  .upcoming-session-mb-date-us {
    position: relative;
    left: 20px;
  }

  .dashboard-card2 {
    width: 148px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #eee;
    background: linear-gradient(
      180deg,
      #47d2d4 0%,
      rgba(71, 210, 212, 0.27) 100%
    );
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .asses-score {
    position: relative;
    left: 85px;
    bottom: 35px;
  }
  .dashboard-card3 {
    width: 148px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #eee;
    background: linear-gradient(
      180deg,
      #f7da54 0%,
      rgba(247, 218, 84, 0.44) 100%
    );
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .asses-score1 {
    position: relative;
    left: 80px;
    bottom: 35px;
  }

  .dashboard-cards-sec {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  .dash2-up-ev {
    display: flex;
    align-items: center;
    gap: 85px;
    margin-bottom: 24px;
  }

  .upcoming-event-p {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .today-p {
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .upcoming-time {
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 23px;
    border-radius: 5px;
    border: 1px solid #eee;
  }

  .dash-counsellor-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .dash-counsellor-dash2 {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 28px;
    width: 290px;
    height: 167px;
    border-radius: 10px;
    background: #f2f2f2;
    overflow: hidden;
  }

  .counsellor-details img {
    width: 40px;
    height: 40px;
  }

  .counsellor-name-dash2 {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .anx-dp-dash2 {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .schedule-date img {
    width: 16px;
    height: 16px;
  }

  .schedule-date-t-dash2 {
    color: #444;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .schedule-cancel-dash2 {
    display: none;
  }

  .join-reschedule-dash2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 28px;
  }

  .join-btn-dash2 {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .reschedule-dash2 {
    color: #566a95;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .cancel-dash {
    color: #566a95;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .res-not-inc {
    display: none;
  }

  .client-reschedule {
    display: none;
  }

  .client-name-h1 {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .client-desc {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .client-btn {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .client-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }

  .upcoming-left-dash2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    width: 328px;
    height: 400px;
  }

  .left-content-2 {
    margin-top: -30px;
  }

  .left-content-2,
  .left-content-1,
  .left-content {
    display: flex;
    width: 312px;
    padding: 16px 8px;
    align-items: center;
    gap: 25px;
  }

  .client-reschedule-chat {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-session {
    display: none;
  }

  .upcoming-session-mb-2 {
    gap: 165px;
    margin-bottom: -10px;
  }

  .community-meeting {
    display: none;
  }

  .upcoming-session-mb-comm {
    gap: 75px;
  }

  .last-cont-btn-r {
    display: none;
  }

  .already-join {
    display: none;
  }

  .community-meeting-time-p1 {
    display: none;
  }

  .community-meeting-h {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .community-meeting-p {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .last-cont-btn {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .community-meeting-info img {
    width: 40px;
    height: 40px;
  }

  .res-time-schedule {
    display: flex;
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .last-container {
    width: 330px;
    height: 293.696px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #eee;
    background: #fff;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 32px;
  }

  .gray {
    width: 330px;
  }

  .community-meeting-info {
    margin-left: 10px;
  }
}
