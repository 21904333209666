.profile-container {
  padding-left: 47px;
  padding-top: 60px;
}

.profile-starter {
  width: 82px;
  height: 61px;
  flex-shrink: 0;
}

.dashboard1-left-sa {
  height: -800px;
}

.profile-starter-h {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile-starter-p {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-subtopic-cont {
  display: flex;
  align-items: flex-end;
  gap: 714px;
  padding-bottom: 64px;
}

.profile-subtopic-cont-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 54px;
}

.profile-sub-l {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-img-merge {
  display: flex;
  align-items: flex-end;
  gap: -33px;
}

.profile-subtopic-img2 {
  position: relative;
  left: -33px;
}

.profile-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
}

.profile-form-cont {
  display: flex;
  align-items: flex-start;
  gap: 105px;
  padding-left: 111.5px;
}

.profile-form-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.profile-form-info label {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.profile-form-info input {
  display: flex;
  width: 402px;
  padding: 14px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f2f2f2;
  border: none;
}

.profile-form-info label span {
  color: #fe0303;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.profile-form-info select {
  display: flex;
  width: 402px;
  padding: 14px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f2f2f2;
  border: none;
}

.profile-ques-cont {
  padding-left: 111.5px;
  padding-top: 41px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.profile-ques-input {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  padding-left: 21px;
  padding-top: 14px;
}

.profile-ques-box {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f2f2f2;
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-ques-content {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  padding-bottom: 24px;
}

.profile-ques-box img {
  cursor: pointer;
}

.profile-img-merge img {
  cursor: pointer;
}

.profile-assessment-banner {
  padding-left: 47px;
}

@media only screen and (max-width: 720px) {
  .dash-right-mb {
    width: 360px;
    height: 1878px;
  }
  .upcoming-session {
    display: none;
  }
  .dashboard1-left {
    display: none;
  }

  .dashboard1-right-container {
    padding-left: 24px;
    padding-top: 30px;
  }

  .dashboard1-right-starter {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: -80px;
  }

  .upcoming-events-mb {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
  }

  .upcoming-event-p {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dash-counsellor-banner {
    display: none;
  }

  .dash-counsellor-banner-mb {
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: -25px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
  }

  .dash-counsellor-banner-mb-imgl {
    width: 80.061px;
    height: 40px;
  }

  .dash-counsellor-banner-mb-imgr {
    width: 141.2px;
    height: 100px;
  }

  .dash-counsellor-banner-mb-u {
    display: flex;
    padding: 0px 37px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .dash-counsellor-banner-mb-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .dash-counsellor-banner-mb-cont-p {
    color: #333;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dash-counsellor-banner-mb-cont-btn {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-session-mb {
    display: flex;
    align-items: center;
    gap: 48px;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  .upcoming-session-mb-p {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-session-box-mb-flex {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .upcoming-session-mb-date {
    color: #333;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .right-mid-photo {
    display: none;
  }

  .upcoming-left-dash {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #f2f2f2;
  }

  .upcoming-container img {
    width: 40px;
    height: 40px;
  }

  .client-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .client-name-h1 {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .client-desc {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .assessment-banner {
    display: none;
  }

  .community-meeting-1 {
    display: none;
  }

  .upcoming-session-dash {
    display: none;
  }

  .client-name-h1-dash {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .client-desc-dash {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .client-btn-dash {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .left-content-2-dash,
  .left-content-1-dash,
  .left-content-dash {
    display: flex;
    width: 312px;
    padding: 16px 8px;
    align-items: center;
    gap: 25px;
  }

  .upcoming-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
  }

  .client-mid-container-dash {
    margin-top: -40px;
  }

  .last-container-dash {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    margin-bottom: 80px;
  }

  .community-meeting-h-dash {
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .community-meeting-p-dash {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .community-meeting-time-p1-dash {
    display: none;
  }

  .already-join-dash {
    display: none;
  }

  .community-meeting-flex-dash {
    display: flex;
    padding: 16px 8px;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    background: #fff;
    width: 336px;
    height: 72px;
  }

  .btn-gap-dash {
    padding: 1px;
  }

  .gray-dash {
    display: flex;
    padding: 16px 8px;
    align-items: center;

    border-radius: 10px 10px 0px 0px;
    background: #f2f2f2;
  }

  .community-meeting-info-dash {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 10px 10px 0px 0px;
  }
  .community-meeting-info-dash img {
    width: 40px;
    height: 40px;
  }

  .last-cont-btn-dash {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .chat-session-mb {
    display: flex;
    width: 312px;
    align-items: center;
    gap: 133px;
  }

  .user-chat-res-mid {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
    gap: -31px;
  }

  .user-chat-m {
    display: flex;
    padding: 0px 82px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: -31px;
  }

  .user-chat-m-btn {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dashboard1-left {
    display: none;
  }

  .dash-counsellor-banner-mb-a {
    padding-left: 30px;
  }
}
