.dashboard1-main {
  display: flex;
}

.dashboard1-left {
  width: 316px;
  height: 1924px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Set the direction to column */
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.15);
}

.right-mid-photo {
  padding-left: 250px;
}

.dashboard-profile {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 60px;
  padding-bottom: 59px;
}

.admin-options {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.admin-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 20px 40px;
}

.admin-list-dashboard {
  display: flex;
  /* justify-content: center; */
  align-items: center;

  gap: 16px;
  /* padding: 20px 40px; */
  width: 317px;
  height: 91px;
  background: url(../images/dash-bg.png) no-repeat;
}

.admin-list-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-left: 40px;
}

.dash-counsellor-banner {
  width: 1024px;
  height: 255px;
  padding: 0px 40px 24px 0px;
  align-items: flex-start;
  gap: 420px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.counsellor-banner-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -24px;
}

.counsellor-banner-right {
  position: absolute;
  margin-left: 700px;
  margin-top: 30px;
}

.counsellor-banner-cont {
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 28px;
  position: absolute;
  margin-top: 106px;
}

.counsellor-banner-cont-h {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.counsellor-banner-btn {
  display: flex;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard1-right-container {
  padding-left: 47px;
  padding-top: 60px;
}

.dashboard1-right-starter {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding-bottom: 52px;
}

.upcoming-events-1 {
  display: flex;
  gap: 760px;
}

.upcoming-session-box-flex {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
}

.dashboard-banner {
  background: url(../images/dash-banner.png) no-repeat;
  width: 1024px;
  height: 255px;
}

.upcoming-session-dash {
  display: flex;
  align-items: flex-start;
  gap: 37px;
  padding-top: 56px;
  padding-bottom: 28px;
}

.upcoming-session-1 {
  display: flex;
  align-items: flex-start;
  gap: 27px;
}

.upcoming-session-box {
  display: flex;
  align-items: center;
  gap: 95px;
}

.upcoming-left {
  width: 500px;
  height: 423.304px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.left-content-1-dash {
  display: inline-flex;
  height: 86.135px;
  align-items: center;
  gap: 102px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f2f2f2;
}

.left-content-dash {
  display: inline-flex;
  align-items: center;
  gap: 102px;
  padding-top: 16.57px;
  padding-bottom: 16.57px;
}

.left-content-2-dash {
  display: inline-flex;
  align-items: center;
  gap: 102px;
  padding-bottom: 16.57px;
}

.upcoming-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.upcoming-left-container-dash {
  padding-top: 34px;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.client-info {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
}

.client-btn-dash {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.client-mid-container-dash {
  display: flex;
  align-items: flex-start;
  gap: 27px;
}

.right-mid-photo {
  width: 500px;
  height: 437px;
  flex-shrink: 0;
}

.lower-banner {
  margin-left: -6px; /* Shifting the lower banner to the left by 10 pixels */
  padding-top: 56px;
  padding-bottom: 56px;
}

.community-date {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 23px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.community-meeting-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 557px;
  padding-bottom: 42.5px;
}

.last-container-dash {
  width: 1024px;
  height: 293.696px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.community-meeting-flex-dash {
  display: inline-flex;
  align-items: center;
  gap: 56px;
  width: 984px;
  height: 88px;
  flex-shrink: 0;
}

.community-meeting-info-dash {
  display: flex;
  align-items: center;
  gap: 6px;
}

.gray-dash {
  border-radius: 10px;
  background: #f2f2f2;
}

.last-box-dash {
  text-align: center;
}

.btn-gap-dash {
  padding-left: 110px;
}

.last-cont-btn-dash {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.admin-list-cont div {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.admin-list {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dash-profile-name {
  color: #333;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard1-right-starter-h1 {
  color: #000;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard1-right-starter-p {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-event-p {
  color: #333;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.today-p {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-time {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  align-items: center;
  gap: 23px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.upcoming-session-p {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-session-date {
  color: #333;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.client-name-h1-dash {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.client-desc {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.community-meeting-p1 {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.community-date-p {
  color: #333;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.community-date img {
  width: 20px;
  height: 20px;
}

.community-meeting-h-dash {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.community-meeting-p-dash {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.info-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.community-meeting-time-p1-dash {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.already-join-dash {
  color: #566a95;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.dash-counsellor-banner-mb {
  display: none;
}

.upcoming-session-mb {
  display: none;
}

.user-chat-res-mid {
  display: none;
}

/* Add this media query to your existing CSS file */
@media only screen and (max-width: 720px) {
  .dash-right-mb {
    width: 360px;
    height: 1878px;
  }
  .upcoming-session {
    display: none;
  }
  .dashboard1-left {
    display: none;
  }

  .dashboard1-right-container {
    padding-left: 24px;
    padding-top: 30px;
  }

  .dashboard1-right-starter {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: -80px;
  }

  .upcoming-events-mb {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
  }

  .upcoming-event-p {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dashboard1-right-starter-mb {
    padding-bottom: 40px;
  }

  .dashboard1-right-starter-p {
    padding-bottom: 40px;
  }

  .dash-counsellor-banner {
    display: none;
  }

  .dash-counsellor-banner-mb {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: -25px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
  }

  .dash-counsellor-banner-mb-imgl {
    width: 80.061px;
    height: 40px;
  }

  .dash-counsellor-banner-mb-imgr {
    width: 141.2px;
    height: 100px;
  }

  .dash-counsellor-banner-mb-u {
    display: flex;
    padding: 0px 37px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .dash-counsellor-banner-mb-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .dash-counsellor-banner-mb-cont-p {
    color: #333;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dash-counsellor-banner-mb-cont-btn {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-session-mb {
    display: flex;
    align-items: center;
    gap: 48px;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  .upcoming-session-mb-p {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .upcoming-session-box-mb-flex {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .upcoming-session-mb-date {
    color: #333;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .right-mid-photo {
    display: none;
  }

  .upcoming-left-dash {
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #f2f2f2;
  }

  .upcoming-container img {
    width: 40px;
    height: 40px;
  }

  .client-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .client-name-h1 {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .client-desc {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .assessment-banner {
    display: none;
  }

  .community-meeting-1 {
    display: none;
  }

  .upcoming-session-dash {
    display: none;
  }

  .client-name-h1-dash {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .client-desc-dash {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .client-btn-dash {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .left-content-2-dash,
  .left-content-1-dash,
  .left-content-dash {
    display: flex;
    width: 312px;
    padding: 16px 8px;
    align-items: center;
    gap: 25px;
  }

  .upcoming-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
  }

  .client-mid-container-dash {
    margin-top: -40px;
  }

  .last-container-dash {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    margin-bottom: 80px;
  }

  .community-meeting-h-dash {
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .community-meeting-p-dash {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .community-meeting-time-p1-dash {
    display: none;
  }

  .already-join-dash {
    display: none;
  }

  .community-meeting-flex-dash {
    display: flex;
    padding: 16px 8px;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    background: #fff;
    width: 336px;
    height: 72px;
  }

  .btn-gap-dash {
    padding: 1px;
  }

  .gray-dash {
    display: flex;
    padding: 16px 8px;
    align-items: center;

    border-radius: 10px 10px 0px 0px;
    background: #f2f2f2;
  }

  .community-meeting-info-dash {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 10px 10px 0px 0px;
  }
  .community-meeting-info-dash img {
    width: 40px;
    height: 40px;
  }

  .last-cont-btn-dash {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .chat-session-mb {
    display: flex;
    width: 312px;
    align-items: center;
    gap: 133px;
  }

  .user-chat-res-mid {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
    gap: -31px;
  }

  .user-chat-m {
    display: flex;
    padding: 0px 82px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: -31px;
  }

  .user-chat-m-btn {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
