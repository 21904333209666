.dashboard-main {
  display: flex;
}

.dashboard-left {
  width: 316px;
  height: 2692px;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  flex-direction: column; /* Set the direction to column */
  align-items: center;
  background: #fff;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.15);
}

.dashboard-profile {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 60px;
  padding-bottom: 59px;
}

.admin-options {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.admin-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 20px 40px;
}

.admin-list-dashboard {
  display: flex;
  /* justify-content: center; */
  align-items: center;

  gap: 16px;
  /* padding: 20px 40px; */
  width: 317px;
  height: 91px;
  background: url(../images/dash-bg.png) no-repeat;
}

.admin-list-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-left: 40px;
}

.admin-list-cont div {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.admin-list {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dash-profile-name {
  color: #333;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-right-container {
  padding-left: 47px;
  padding-top: 60px;
}

.session-starter {
  width: 193px;
  height: 59px;
  flex-shrink: 0;
}

.session-starter-h {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.session-starter-p {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 47px;
}

.upcoming-event {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 715px;
  padding-bottom: 28px;
}

.today-box {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  align-items: center;
  gap: 23px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.upcoming-event-h {
  color: #333;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.today-box-h {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dash-counsellor {
  display: flex;
  justify-content: center;
  display: flex;
  width: 470px;
  height: 160px;
  padding: 24px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 93px;
  border-radius: 10px;
  background: #f2f2f2;
}

.counsellor-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.counsellor-schedule {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.schedule-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.counsellor-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.schedule-cancel {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 8px;
}

.dash-counsellor-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.join-btn {
  display: flex;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.join-reschedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.reschedule {
  color: #1fa9ab;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.counsellor-name {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.counsellor-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.anx-dp {
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.schedule-date-t {
  color: #444;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.schedule-cancel div {
  color: #566a95;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dash-counsellor-flex {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.question-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding-top: 56px;
  padding-bottom: 56px;
}

.questions-flex {
  display: flex;
  align-items: center;
  gap: 45px;
}

.questions-box {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.questions-box-h {
  display: flex;
  padding: 20px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.question-container-h {
  color: #333;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.prev-session {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 32px;
}

.session-filter {
  display: flex;
  align-items: flex-start;
  gap: 515px;
}

.session-filter-date {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.session-filter-dates {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 23px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.session-search-input {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: #f2f2f2;
  border: none;
}

.session-search-img {
  fill: #666;
  width: 14px;
  height: 14px;
}

.previos-session-container {
  display: flex;
  width: 948px;
  align-items: center;
  justify-content: center;
  gap: 59px;
}

.prev-session-flex {
  display: flex;
  align-items: center;
  gap: 6px;
}

.prev-session-options {
  display: flex;
  width: 444px;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}

.prev-session-padding {
  width: 987px;
  height: 88px;
  flex-shrink: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.prev-session-counsellor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.prev-session-counsellor-name {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.prev-session-counsellor-topic {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.prev-session-time {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.prev-session-options div {
  color: #566a95;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.prev-session-padding-gray {
  border-radius: 10px;
  background: #f2f2f2;
}
.view-more-container {
  width: 94%;
  position: relative;
  padding-top: 28px;
  padding-bottom: 56px;
}

.view-more {
  text-align: right;
  color: #566a95;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.previous-session {
  padding-top: 28px;
}

.recommended-counsellor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
}

.recommended-counsellor-h {
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 155% */
  letter-spacing: 0.4px;
  text-transform: capitalize;
}

.recommended-filter {
  display: inline-flex;
  gap: 159px;
}

.recommended-filter-cont {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
}

.recommended-filter-h {
  display: flex;
  align-items: center;
  width: 160px;
  height: 47px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #eee;
}

.recommended-text {
  margin-right: 10px;
  padding-left: 11px;
}

.recom-profile-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-top: 28px;
}

.Recom-Profile {
  width: 1024px;
  height: 227px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 20px 20px 20px;
}

.recom-profile-upper {
  display: flex;
  align-items: center;
  gap: 389px;
}

.recom-counsellor-profile {
  display: flex;
  width: 324.535px;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
}

.recom-profile-name {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}

.recom-profile-desc {
  width: 198px;
  height: 38px;
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  padding-top: 4px;
  padding-bottom: 8px;
}

.recom-profile-l {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.recom-view {
  color: #566a95;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 193.75% */
  letter-spacing: 0.32px;
  text-transform: capitalize;
  cursor: pointer;
}

.recom-book {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.counsellor-spec {
  display: flex;
  align-items: center;
  gap: 16px;
}

.counsellor-spec-cont {
  display: flex;
  width: 860.724px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 10px;
}

.counsellor-spec-h {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}

.counsellor-spec-p {
  width: 688px;
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 193.75% */
  letter-spacing: 0.32px;
}

.counsellor-spec-p span {
  color: #566a95;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.32px;
}

.counsellor-spec-p1 {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 193.75% */
  letter-spacing: 0.32px;
}

.tick-icon {
  width: 20px;
  height: 20px;
}

.spec-icon {
  width: 20px;
  height: 20px;
}

.prev-session-mb {
  display: none;
}

.mb-recom {
  display: none;
}
.upcoming-session-mb {
  display: none;
}
/* Media query */

@media only screen and (max-width: 720px) {
  .dashboard-left {
    display: none;
  }

  .prev-session-mb-1 {
    position: relative;
    right: 18px;
    margin-bottom: -80px;
  }

  .dash-counsellor-banner-mb-se {
    margin-bottom: 40px;
    margin-top: -40px;
  }

  .questions-flex img {
    display: none;
  }

  .questions-flex {
    display: block;
    align-items: center;
    gap: 45px;
  }

  .upcoming-event {
    display: none;
  }

  .questions-box {
    display: block;
    align-items: flex-start;
    gap: 20px;
  }

  .questions-box-h-p {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .prev-session-mb {
    display: flex;
  }

  .previous-session {
    display: none;
  }

  .mb-prev-rl {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 99px;
    display: flex;
    width: 312px;
    padding: 16px 8px;
    align-items: center;
  }

  .mb-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .mb-profile-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .mb-profile-cont-u {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mb-profile-cont-l {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .mb-more {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 312px;
    padding: 4px 8px 16px 8px;
  }

  .mb-more-u {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  .mb-more-l {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding-top: 10px;
  }

  .mb-more-l div {
    color: #566a95;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .mb-prev {
    display: block;
    width: 312px;
    padding: 4px 8px 16px 8px;
    align-items: center;
    gap: 25px;
  }

  .prev-session-mb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
  }

  .view-more-container {
    width: 94%;
    position: relative;
    right: 700px;
    padding-top: 28px;
    padding-bottom: 56px;
  }

  .view-more {
    color: #566a95;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .recom-profile-container {
    display: none;
  }

  .mb-recom {
    display: flex;
    align-items: center;
    gap: 52px;
    display: flex;
    width: 312px;
    padding: 16px 8px;
    align-items: center;
    gap: 25px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
    background: #fff;
  }

  .mb-recom-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .mb-recom-pro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .mb-recom-pro-u {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mb-recom-pro-l {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .mb-recom-btn {
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #24b8bb;
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .recommended-filter {
    display: none;
  }

  .mb-recom-cont {
    padding-top: 32px;
  }

  .recommended-counsellor-h {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
