.comm-sub-option {
  position: relative;
  display: flex;
  width: 316px;
  padding-top: 20px;
  padding-bottom: 20px;
  left: 60px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.comm-sub-option-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.comm-sub-p {
  color: #24b8bb;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.comm-sub-h {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.comm-starter {
  width: 145px;
  height: 61px;
  flex-shrink: 0;
}

.comm-starter-h {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 7px;
}

.comm-starter-p {
  color: #555;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.recomm-cal {
  padding-right: 16px;
}

.comm-meet-container {
  display: flex;
  width: 450px;
  height: 280px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  margin-top: 0px;

  gap: 16px;
  flex-shrink: 0;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.comm-meet-upper {
  display: flex;
  align-items: flex-start;
  gap: 93px;
}

.comm-banner-img {
  width: 502px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
}

.comm-meet-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.comm-meet-info-h1 {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
}

.comm-meet-info-h2 {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.recom-heading {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 56px;
  margin-bottom: -32px;
}

.comm-meet-info-h3 {
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 193.75% */
  letter-spacing: 0.32px;
}

.comm-meet-joined {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.comm-meet-joined-h {
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.comm-meet-lower {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.comm-meet-lower-h {
  width: 432px;
  height: 59px;
  color: #666;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}

.comm-meet-join-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
}

.comm-meet-btn {
  display: flex;
  width: 336px;
  height: 30px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: #24b8bb;
  color: #fff;
  text-shadow: 0px 5px 20px rgba(37, 206, 209, 0.25);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px; /* 155% */
  letter-spacing: 0.4px;
  text-transform: capitalize;
  cursor: pointer;
}

.comm-container {
  display: flex;
  flex-direction: column;
}

.comm-banner-img {
  margin-right: 0;
}

.comm-meet-main {
  margin-left: 0;
}

.comm-meet-grid {
  display: grid;
  grid-template-columns: auto auto; /* Two columns */
  grid-template-rows: auto auto; /* Two rows */
  gap: 10px; /* Gap between grid items */
}

.grid-item {
  padding: 23px;
}

.try-btn {
  color: #24b8bb;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
  display: inline-flex;
  padding: 8px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
  margin: 10px;
}

.update-recomm {
  padding-top: 56px;
  padding-bottom: 40px;
}
.mb-prev-comm-msg {
  display: none;
}
.try {
  width: 502px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(../images/comm-meet-banner.png),
    lightgray 0px -17.354px / 100% 167.354% no-repeat;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.prev-session-mb {
  display: none;
}

@media only screen and (max-width: 720px) {
  .prev-session-mb {
    display: flex;
  }

  .previous-session {
    display: none;
  }

  .mb-prev-rl {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 99px;
    display: flex;
    width: 312px;
    padding: 16px 8px;
    align-items: center;
  }

  .mb-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .mb-profile-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .mb-profile-cont-u {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .mb-profile-cont-l {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .mb-more {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 312px;
    padding: 4px 8px 16px 8px;
  }

  .mb-more-u {
    color: #555;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  .mb-more-l {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding-top: 10px;
  }

  .mb-more-l div {
    color: #566a95;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .last-container {
    display: none;
  }

  .prev-session-mb-comm {
    margin-top: 40px;
  }

  .mb-prev-comm-msg {
    display: flex;
    align-items: center;
    gap: 70px;
    padding-top: 32px;
  }

  .mb-prev-comm-msg {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .comm-meet-grid {
    display: block;
    grid-template-columns: auto auto; /* Two columns */
    grid-template-rows: auto auto; /* Two rows */
    gap: 10px; /* Gap between grid items */
    transform: scale(0.9);
    position: relative;
    bottom: 80px;
  }

  .comm-container {
    transform: scale(0.7);
    position: relative;
    right: 120px;
    margin-top: -100px;
    margin-bottom: -120px;
  }

  .mb-prev-comm-msg-up {
    gap: 170px;
  }

  .recom-heading {
    display: none;
  }
}
