.csess-dashboard-left {
  width: 316px;
  height: 1500px;
  flex-shrink: 0;
}

.csess-prev-session {
  padding-top: 56px;
}

.prev-session-mb {
  display: none;
}

@media only screen and (max-width: 720px) {
  .session-starter-h {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .session-starter-p {
    color: #555;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .upcoming-session-mb-p {
    font-size: 14px;
  }

  .session-filter {
    display: none;
  }

  .prev-session-mb {
    display: flex;
  }

  .prev-session-mb-sc {
    position: relative;
    right: 18px;
  }
}
