/* Main chat container */
.chat-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  font-family: "League Spartan", sans-serif;
}

.dashboard-left-1 {
  height: 800px;
}

.new-height {
  overflow-y: hidden;
}

.input-chat {
  border: none;
}

.input-chat:focus {
  border: solid 2px black;
}

.chat-land {
  position: relative;
  height: 100vh;
  min-height: 950px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chat-box {
  width: 100%;
  height: 90%;
  position: relative;
}

.user-details {
  width: 100%;
  height: 87px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: #24b8bb;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
}

.zenchat {
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.back-logo {
  transform: scale(0.4);
  height: 40px;
  width: 32px;
  cursor: pointer;
}

.chat-logo {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-left: 16px;
}

.mesaage-date {
  width: fit-content;
  padding: 8px 16px;
  border-radius: 30px;
  background: #f2f2f2;
  color: #555;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

/* Message section */
.message-section {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
}

/* Message bubble containers */
.chat-con-cont,
.user-msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

/* Received message (from Zenbot) */
.chat-con-cont {
  justify-content: flex-start;
}

.blu-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.con-det {
  background-color: #e3f2fd; /* Match with navbar color */
  border-radius: 15px;
  padding: 10px 15px;
  color: #333; /* Text color */
  max-width: 60%;
  word-wrap: break-word;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  position: relative;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Shadow effect */
  transition: background-color 0.3s, transform 0.3s;
}

.con-det:hover {
  background-color: #d0e7ff;
  transform: scale(1.02);
}

/* Remove unwanted sign */
.con-det::after {
  display: none;
}

/* Sent message (from user) */
.user-msg {
  justify-content: flex-end;
}

.user-msg-conti {
  background-color: #25ced1;
  border-radius: 15px;
  padding: 10px 15px;
  color: #fff;
  max-width: 60%;
  word-wrap: break-word;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  position: relative;
  transition: background-color 0.3s, transform 0.3s;
}

.user-msg-conti:hover {
  background-color: #1fb3b8;
  transform: scale(1.02);
}

/* Remove unwanted sign */
.user-msg-conti::after {
  display: none;
}

/* Timestamps */
.chat-time,
.user-rep-time {
  font-size: 12px;
  color: #999;
  margin-top: 2px;
}

.chat-time {
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.user-rep-time {
  margin-right: 50px;
  text-align: right;
}

/* Feedback Buttons */
.feedback-buttons {
  margin-left: 10px;
  display: flex;
  gap: 10px;
  margin-top: 5px;
  animation: fadeIn 0.3s ease-in-out;
}

.feedback-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  transition: transform 0.2s, color 0.3s;
}

.feedback-button:hover {
  transform: scale(1.2);
}

.like-button {
  color: #4caf50;
}

.dislike-button {
  color: #f44336;
}

/* Loading chat */
.loading-chat {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.loading-logo {
  height: 32px;
  margin-right: 10px;
}

.is-typing {
  width: 85px;
  justify-content: space-around;
  display: flex;
}

.jump1,
.jump2,
.jump3,
.jump4,
.jump5 {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: gray;
}

.jump1 {
  animation: typing 1.5s linear infinite;
  animation-delay: 0.1s;
}

.jump2 {
  animation: typing 1.5s linear infinite;
  animation-delay: 0.2s;
}

.jump3 {
  animation: typing 1.5s linear infinite;
  animation-delay: 0.3s;
}

.jump4 {
  animation: typing 1.5s linear infinite;
  animation-delay: 0.4s;
}

.jump5 {
  animation: typing 1.5s linear infinite;
  animation-delay: 0.5s;
}

@keyframes typing {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Message sender input */
.message-sender {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  box-sizing: border-box;
}

.typer-cont {
  flex: 1;
  display: flex;
  align-items: center;
}

.typer {
  flex: 1;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #f1f0f0;
  margin-right: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.typer:focus {
  outline: none;
  background-color: #e8e8e8;
  box-shadow: 0 0 5px rgba(38, 215, 218, 0.5);
}

.typer::placeholder {
  color: #999;
}

.pi-arrow {
  font-size: 36px;
  color: rgba(38, 215, 218, 1);
  cursor: pointer;
  transition: transform 0.2s;
}

.pi-arrow:hover {
  transform: rotate(90deg);
}

.pi-arrow.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.pi-arrow.enabled:hover {
  color: rgba(25, 135, 156, 1);
}

/* Responsive design */
@media only screen and (max-width: 600px) {
  .con-det,
  .user-msg-conti {
    max-width: 80%;
    font-size: 14px;
  }

  .blu-logo {
    width: 30px;
    height: 30px;
  }

  .chat-time,
  .user-rep-time {
    font-size: 10px;
  }

  .message-section {
    width: 100%;
  }

  .user-details {
    width: 100%;
  }

  .message-sender {
    width: 100%;
  }

  .chat-main {
    margin-left: 0;
    margin-right: 0;
  }

  .chat-logo {
    height: 50px;
    width: 50px;
  }

  .back-logo {
    top: 0;
  }
}

/* Additional styles */
.reset {
  transform: translateX(0px);
}

/* Optional: Hide the chat list by default on smaller screens */
@media only screen and (max-width: 1300px) {
  .chat-list {
    display: none;
  }
}

/* Styles for the date bubble */
.mesaage-date {
  width: fit-content;
  padding: 8px 16px;
  border-radius: 30px;
  background: #f2f2f2;
  color: #555;
  margin: 20px auto;
  font-size: 14px;
}

/* Adjust the chat-main for smaller screens */
@media only screen and (max-width: 400px) {
  .chat-main {
    margin-top: 0;
    margin-left: 0;
  }

  .message-section {
    height: calc(100vh - 200px);
  }

  .user-details {
    height: 70px;
    padding: 0 10px;
  }

  .zenchat {
    font-size: 18px;
    margin-left: 10px;
  }

  .message-sender {
    padding: 5px;
  }

  .typer {
    font-size: 14px;
  }

  .pi-arrow {
    font-size: 30px;
  }
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeInModal 0.5s ease-in-out;
}

@keyframes fadeInModal {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 20px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  animation: slideDown 0.5s ease-in-out;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

.modal h2 {
  margin-top: 0;
  text-align: center;
  color: #24b8bb;
  font-size: 28px;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
  font-size: 16px;
}

.form-group input,
.form-group select {
  padding: 12px 15px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #24b8bb;
  box-shadow: 0 0 8px rgba(36, 184, 187, 0.2);
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 14px;
  background-color: #24b8bb;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
}

.submit-button:hover {
  background-color: #1a8a90;
  transform: translateY(-2px);
}

/* Modal Content Box */
.modal .form-group {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

/* Placeholder Colors */
.form-group input::placeholder,
.form-group select {
  color: #aaa;
}

/* Feedback Buttons */
.feedback-buttons {
  margin-left: 10px;
  display: flex;
  gap: 10px;
  margin-top: 5px;
  animation: fadeIn 0.3s ease-in-out;
}

.feedback-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  transition: transform 0.2s, color 0.3s;
}

.feedback-button:hover {
  transform: scale(1.3);
}

.like-button {
  color: #4caf50;
}

.dislike-button {
  color: #f44336;
}

/* FadeIn Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
