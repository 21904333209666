.contact-us {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-left: 108px;
}

.c-header {
  margin-top: 5px;
  color: #333;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.84px;
  text-transform: capitalize;
}

.c-header::after {
  content: "";
  width: 8%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 210, 0, 1);
  display: block;
  height: 8px;
}

.c-content {
  font-size: 20px;
  margin-bottom: 20px;
  width: 1224px;
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  position: relative;
  bottom: 30px;
}

.form-fill {
  margin-top: 30px;
  height: auto;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  text-align: left;
  padding-left: 108px;
}

.form-heading {
  color: #444;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px; /* 129.167% */
  letter-spacing: 0.48px;
  text-transform: capitalize;
}

.user-name-c-1 {
  color: #444;
}

.name-email {
  display: flex;
  gap: 4%;
}

.user-name-c-1,
.user-email {
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
}

.user-name-c-1 label,
.user-email label {
  font-size: 18px;
  font-weight: bold;
}

.fname-1,
.email {
  width: calc(100% - 20px);
  height: 50%;
  border: none;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  background-color: rgba(242, 242, 242, 1);
}

.query {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 48%;
  gap: 20px;
}

.query label {
  font-size: 18px;
  font-weight: bold;
}

.landing-image-3 {
  height: 50%;
  width: 1521px;
  margin: 0;
  padding: 0;
}

.question {
  width: 100%;
  resize: none;
  height: 100px;
  padding-top: 5px;
  padding-left: 5px;
  background: rgba(242, 242, 242, 1);
  border-radius: 4px;
  border: none;
  outline: none;
}

.question:focus {
  border: 1px solid #25ced1;
  background: white;
}

input:focus {
  background: white;
}

.submit-btn button {
  height: 60px;
  width: 120px;
  border-radius: 20px;
  color: white;
  border: none;
  background: rgba(37, 206, 209, 1);
  font-size: 18px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .landing-image-3 {
    display: none;
  }

  .user-email label {
    font-size: 14px;
    font-weight: bold;
  }

  .user-name-c-1 label {
    font-size: 14px;
    font-weight: bold;
  }

  .query label {
    font-size: 14px;
    font-weight: bold;
  }

  .form-heading {
    color: #444;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px; /* 129.167% */
    letter-spacing: 0.48px;
    text-transform: capitalize;
  }

  .contact-us {
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-left: 108px;
    right: 60px;
  }

  .main-content-con {
    margin-top: 100px;
  }

  .c-header {
    margin-top: 5px;
    color: #333;
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.84px;
    text-transform: capitalize;
  }

  .c-header::after {
    content: "";
    width: 30%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 210, 0, 1);
    display: block;
    height: 8px;
  }

  .c-content {
    margin-bottom: 20px;
    width: 250px;
    color: #333;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }

  .name-email {
    display: block;
    gap: 4%;
  }

  .form-fill {
    margin-top: 30px;
    height: auto;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    text-align: left;
    padding-left: 40px;
  }

  .c-header {
    margin-top: 5px;
    color: #333;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.84px;
    text-transform: capitalize;
    margin-left: 40px;
    position: relative;
    top: 0px;
  }

  .c-header::after {
    content: "";
    width: 30%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 210, 0, 1);
    display: block;
    height: 5px;
    border-radius: 10%;
    position: relative;
    bottom: 5px;
  }

  .user-name-c-1 input {
    width: 250px;
  }

  .user-name-c-1 input::placeholder,
  .user-email input::placeholder,
  .query textarea::placeholder {
    font-size: 14px;
  }

  .fname-1,
  .email {
    width: 250px;
    height: 50%;
    border: none;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    background-color: rgba(242, 242, 242, 1);
  }

  .question {
    width: 250px;
    resize: none;
    height: 100px;
    padding: 10px;
    background: rgba(242, 242, 242, 1);
    border-radius: 4px;
    border: none;
  }

  .submit-btn button {
    height: 60px;
    width: 120px;
    border-radius: 20px;
    color: white;
    border: none;
    background: rgba(37, 206, 209, 1);
    font-size: 18px;
    cursor: pointer;
    margin-left: 65px;
    transform: scale(0.7);
    position: relative;
    bottom: 20px;
  }
}
