.update-comm-left {
  width: 316px;
  height: 1616px;
  flex-shrink: 0;
}

.community2-starter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 500px;
  padding-bottom: 37px;
}

.community2-starter-l {
  width: 283px;
  height: 62px;
  flex-shrink: 0;
}

.community2-starter-h {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.community2-starter-p {
  color: #555;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 8px;
}

.comm-start-btn {
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #ffb800;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.comm-start-btn1 {
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #ffb800;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: none;
}

.comm-grp-cat {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  padding-bottom: 40px;
}

.comm-grp-cats {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.grp-cat {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.comm-anx {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #24b8bb;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.comm-other {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #666;
  color: #666;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.comm-grp-chats-container {
  width: 1027px;
  height: auto;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: -2px 2px 8px 2px rgba(0, 0, 0, 0.1);
  padding-left: 31px;
  padding-top: 30px;
  padding-bottom: 30px;

  margin-bottom: 24px;
}

.comm-grp-chats-container-u {
  display: inline-flex;
  padding: 0px 10px;
  align-items: flex-start;
  gap: 26px;
}

.comm-grp-inner {
  display: flex;
  width: 871px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.comm-grp-names {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.comm-grp-names-h {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
}

.comm-grp-names-p {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.comm-grp-chats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.comm-grp-chats-h {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 132.687%; /* 23.884px */
  letter-spacing: 0.36px;
}

.comm-grp-chats-p {
  width: 871px;
  height: auto;
  color: #444;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 132.687%; /* 23.884px */
  letter-spacing: 0.36px;
  margin-bottom: 16px;
}

.comm-dis-rxn {
  display: flex;
  align-items: center;
  gap: 24px;
}

.comm-dis-rxns {
  display: flex;
  align-items: center;
  gap: 12px;
}

.grey-mini {
  width: 56px;
  height: 56px;
}

.comm-grp-chats-container-sub {
  display: inline-flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 28px;
  padding-left: 121px;
  padding-top: 35px;
  width: 100%;
}

.comm-grp-chats-container-m {
  display: flex;
  align-items: flex-start;
  gap: 26px;
}

.comm-grp-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.comm-grp-content-h {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
}

.comm-grp-content-h span {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.comm-grp-content-p {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.show-less-comm {
  position: relative;
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;

  gap: 8px;
}

.show-less-h {
  color: #566a95;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.comm-dis-rxns-h1 {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}

.comm-dis-rxns-h2 {
  color: #566a95;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
}

.comm-grp-lower-cont {
  width: 1027px;
  height: 261px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: -2px 2px 8px 2px rgba(0, 0, 0, 0.1);
  padding-left: 21px;
  padding-top: 30px;
  margin-top: 30px;
}

.comm-grp-mb {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

@media only screen and (max-width: 720px) {
  .comm-grp-mb {
    display: flex;
    flex-direction: column;
  }

  .comm-grp-cats {
    transform: scale(0.8);
    position: relative;
    right: 20px;
  }

  .comm-grp-chats-container {
    width: 320px;
    height: 1119px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #eee;
    background: #fff;
    box-shadow: -2px 2px 8px 2px rgba(0, 0, 0, 0.1);
    padding-left: 31px;
    padding-top: 30px;
    margin-bottom: 43px;
  }

  .comm-grp-cat {
    position: relative;
    right: 15px;
  }

  .comm-grp-chats-p {
    width: 228px;
    height: 147px;
    flex-shrink: 0;
    color: #444;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 132.687%; /* 15.922px */
    letter-spacing: 0.24px;
  }

  .gray-cirl {
    width: 40px;
    height: 40px;
  }

  .comm-grp-names-h {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
  }

  .comm-start-btn1 {
    display: inline-flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #ffb800;
    color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .community2-starter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin-left: -30px;
    padding-bottom: 37px;
  }

  .community2-starter-l2 {
    position: relative;
    left: 30px;
    top: 10px;
  }

  .comm-grp-names-p {
    color: #333;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .comm-grp-chats-h {
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 132.687%; /* 18.576px */
    letter-spacing: 0.28px;
    width: 179px;
  }

  .comm-dis-rxns-h1 {
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
  }

  .comm-dis-rxns-h2 {
    color: #333;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
  }

  .comm-grp-chats-container-u {
    display: inline-flex;
    padding: 0px 10px;
    align-items: flex-start;
    gap: 26px;
  }

  .comm-grp-chats-container {
    position: relative;
    right: 15px;
  }

  .comm-grp-chats-container {
    padding-left: 1px;
    padding-top: 10px;
    margin-bottom: 43px;
  }

  .comm-grp-inner {
    display: flex;
    width: 871px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    position: relative;
    right: 10px;
  }

  .grey-mini {
    width: 40px;
    height: 40px;
  }
  .comm-grp-chats-container-sub {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    padding-left: 60px;
    padding-top: 35px;
  }

  .comm-grp-content-h {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
  }

  .comm-grp-content-h span {
    color: #000;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .comm-grp-content-p {
    width: 186px;
    height: 125.77px;
    flex-shrink: 0;
    color: #444;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 132.687%; /* 15.922px */
    letter-spacing: 0.24px;
  }

  .comm-grp-chats-container-m {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  .comm-grp-chats-container-u-2 {
    position: relative;
    bottom: 430px;
    right: -5px;
  }

  .comm-grp-lower-cont {
    width: 0;
    height: 0;
    flex-shrink: 0;
    border-radius: 0;
    border: 0 solid #eee;
    background: #fff;
    box-shadow: 0;
    padding-left: 0;
    padding-top: 0;
    margin-top: 0;
  }

  .comm-grp-lower-cont-0 {
    display: none;
  }
}
