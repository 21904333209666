.book-left-starter {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 112px;
}

.book-square-def {
  display: flex;
  width: 120px;
  height: 120px;
  align-items: flex-end;
  gap: -33px;
  border-radius: 10px;
  background: #f2f2f2;
}

.book-left-u {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.book-left-l {
  color: #666;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.book-start-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.book-blue-circle {
  width: 20px;
  height: 20px;
  background: #25ced1;
  border-radius: 50%;
  z-index: 1;
}

.book-gray-circle {
  width: 20px;
  height: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  z-index: 1;
}

.book-progress-row {
  display: inline-flex;
  /* justify-content: center;
  align-items: center; */
  gap: 21px;
}

.book-progress-cont {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;
  z-index: 1;
}

.book-progress-row-dark {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.book-progress-row-light {
  color: #555;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.book-gray-line {
  position: relative;
  bottom: 190px;
  right: 68px;
  z-index: 0;
}

.book-blue-line {
  position: relative;
  bottom: 370px;
  right: 68px;
  z-index: 0;
}

.book-session-starter {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 41px;
}

.book-session-starter div {
  color: #333;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.book-session-starter img {
  width: 10px;
  height: 20px;
  cursor: pointer;
}

.book-session-schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding-left: 70px;
}

.choose-time {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.book-session-date-cont {
  display: flex;
  align-items: center;
  gap: 44px;
}

.book-session-date-mid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 62.53px;
  width: 460px;
  height: 125px;
  border-radius: 15px;
  border: 1px solid #ffa5a5;
}

.book-session-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.book-session-day-u {
  color: #333;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.book-session-day-l {
  color: #555;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.book-bar {
  cursor: pointer;
}

.day-option {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px;
}

.time-select-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
}

.day-option-d {
  color: #333;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.day-option-l {
  color: #555;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.time-option {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.time-option-1 {
  display: flex;
  gap: 24px;
}

.time-white-btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.time-blue-btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #25ced1;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.book-session-schedule-mid {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
}

.select-session-dur {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.select-session-dur-1 {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.selection-duration {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.selection-duration-btn1 {
  width: 140px;
  height: 109px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #25ced1;
  background: #f2f2f2;
  color: #25ced1;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.687%; /* 32.085px */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.selection-duration-btn2 {
  width: 140px;
  height: 109px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f2f2f2;
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.687%; /* 32.085px */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.session-pricing-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}

.session-pricing-selection-row {
  display: flex;
  align-items: center;
  gap: 398px;
}

.session-freq {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.session-freq div {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.session-selection-price {
  color: #333;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.proceed-btn {
  display: inline-flex;
  padding: 16px 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #25ced1;
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
