.main-content {
  height: auto;
  background-color: rgb(255, 255, 255);

  /* background-color: rgb(5, 48, 57); */
}

.landing-image-1 {
  height: 50%;
  width: 1521px;
  margin: 0;
  padding: 0;
}

.main-content {
  text-align: center;
}

.hero {
  position: relative;
}

.free-assessment {
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 5px;
  opacity: 0.85;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  bottom: 390px;
  right: 250px;
  margin-bottom: -270px;
}

.choices {
  display: flex;
  gap: 2px;
  font-weight: 600;
  font-size: 2.4vw;
}

.description {
  font-size: 1vw;
  text-align: left;
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.32px;
  text-transform: capitalize;
}
.descp2 {
  font-size: 18px;
  font-weight: 400;
}

.button-free button {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #ffb800;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px; /* 155% */
  letter-spacing: 0.4px;
  text-transform: capitalize;
  border: none;
}

.choices div {
  color: #222;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.free-assessment-mb {
  display: none;
}

@media only screen and (max-width: 600px) {
  .free-assessment {
    position: relative;
    left: 21px;
    width: 100%;
  }
  .hero {
    width: 100%;
  }
  .free-assessment-mb {
    width: 100%;
  }
  .main-content {
    width: 100%;
  }
  .free-assessment {
    display: none;
  }

  .landing-image-1 {
    display: none;
  }

  .free-assessment-mb {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 150px;
  }

  .free-assessment-mb-c {
    margin-left: -15px;
  }

  .free-assessment-mb-1 {
    color: #222;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .free-assessment-mb-2 {
    color: #444;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    width: 90vw;
  }

  .free-assessment-mb-3 {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: #ffb800;
    color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px; /* 155% */
    letter-spacing: 0.4px;
    text-transform: capitalize;
    box-shadow: 1px 10px 30px -10px #ffb800;
    transform: scale(0.9);
  }

  .free-assessment-mb-3-u {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1100px) {
  .free-assessment {
    position: relative;
    left: 100px;
  }
}
